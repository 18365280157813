
import styles from "./styles.module.scss";

export default function Loader() {
  return (
    <div className="flex justify-center items-center h-40">
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-primary-blue"></div>
    </div>
  );
}

export  function PageLoder() {
  return (
    <div className={styles.vh_100}
    >
      <div className="flex justify-center items-center h-40">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-primary-blue"></div>
      </div>
    </div>
  );
}