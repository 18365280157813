import { memo, useEffect, useRef, useState } from "react";

const COUNTER_SECONDS = 180;

const TimerRunner = ({ setResendDisabled }) => {
  const [countdown, setCountdown] = useState(COUNTER_SECONDS);
  const counterRef = useRef(null);

  // Start the countdown when the component mounts
  const countDownStart = () => {

    setResendDisabled(true);
    setCountdown(COUNTER_SECONDS);

    counterRef.current = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          // Clear the interval if the countdown is over
          setResendDisabled(false);
          clearInterval(counterRef.current);
          return 0;
        } else {
          return prevCountdown - 1;
        }
      });
    }, 1000);


    return () => {
      clearInterval(counterRef.current);
    };
  };

  useEffect(countDownStart, []);

  return (
    <span style={{ marginLeft: 4 }}>
      in{" "}
      {Math.floor(countdown / 60)
        .toString()
        .padStart(2, "0")}{" "}
      min {(countdown % 60).toString().padStart(2, "0")} sec
    </span>
  );
};

export default memo(TimerRunner);
