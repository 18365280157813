import Loader from "@/components/atoms/Loader/Loader";
import LeadAPIFailedErrorMsg from "@/components/molecules/LeadAPIFailedErrorMsg.tsx/LeadAPIFailedErrorMsg";
import { getGlobalStateValue } from "@/context/utils";
import { pushToDataLayer } from "@/helpers/gtag";
import { StorageService } from "@/helpers/storage.service";
import VerifyOTPForm from "@molecules/Forms/VerifyOTPForm";
import { usePathname, useRouter } from "next/navigation";

import {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
  useEffect,
} from "react";

export const OTPContext = createContext<any>({});

export function OTPContextProvider({ children, isLambdaAPI = false }) {
  const [formCallback, setFormCallback] = useState(null);
  const [otpGaCb, setVerifyOtpGaCb] = useState(null);
  const [isOTPSent, setOTPSent] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isLoading, loading] = useState(false);
  const [leadResponse, setLeadResponse] = useState(null);
  const [otpMessage, setMessage] = useState(null);
  const [otpResponse, setResponse] = useState(null);
  const [otpVerified, setIsOtpVerified] = useState(false);
  const [isInternationalNumber, setIsInternationalNumber] = useState(false);
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const [isNewSignInFlow, setIsNewSignInFlow] = useState(false);
  const [leadErrorHandler, setLeadErrorHandler] = useState(null)
  const [leadError, setLeadError] = useState(false)
  const [leadFailedComp, setLeadFailedComp] = useState(true)
  const pathname = usePathname();


  const handleResendOTP = ({ phone, mobile, phoneNumber, countryCode, emailId, email }) => {
    const mobilePhone = phone || mobile || phoneNumber || emailId || email;

    fetch("/api/otp/send", {
      method: "post",
      body: JSON.stringify({ mobile: mobilePhone }),
    }).then((d) => d.json()).then((d) => {
      if (d.success) {
        setResponse({
          ...d,
          mobile: mobilePhone,
          countryCode: countryCode,
        });
      }
    })
  }


  const handleSendOTP = async (
    { phone, mobile, phoneNumber, countryCode, emailId, email, ...rest },
    cb: any, submitGaCb:()=>void, verifyOtpGaCb : any, checkLeadFail: any
  ) => {
    const mobilePhone = phone || mobile || phoneNumber || emailId || email;
    if(typeof cb === 'function'){
      const res = await cb();
      
        if(!res && countryCode !=="+91"){
          setLeadError(true)
          if(checkLeadFail)
            checkLeadFail(true)
          else setLeadFailedComp(false)
        }
    }

    if(countryCode != "+91"){
      setIsInternationalNumber(true);
      return
    }

    setFormCallback({ cb });
    setVerifyOtpGaCb({ verifyOtpGaCb });
    setLeadErrorHandler({ checkLeadFail })
    loading(true);

  fetch("/api/otp/send", {
    method: "post",
    body: JSON.stringify({ mobile: mobilePhone }),
  })
    .then((d) => d.json())
    .then((d) => {
      loading(false);
      if (d.success) {
        setOTPSent(true);
        setMessage(null);
        delete d.otp;
        setResponse({
          ...d,
          mobile: mobilePhone,
          countryCode: countryCode,
        });
        if(typeof submitGaCb === "function"){
          submitGaCb();
        }
      } else {
        setMessage(d.message);
      }
    })
    .catch((e) => {})
    .finally(() => {
      loading(false);
    });
  }

  const moveToNextStep = () => {
    setOTPSent(false);
    setIsOtpVerified(true);
    setMessage(null);
    setShowLoader(true);
    otpGaCb?.verifyOtpGaCb && otpGaCb.verifyOtpGaCb();
  }

 const leadUpdateApiCall = async (mFilterData = {}) =>{ 
    const res = fetch("/api/leadsquared.com/lead-update", {
        method: "POST",
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        form : { "leadId" : leadResponse?.ProspectId  || "", "Custom5" : "Verified", ...(Object.keys(mFilterData).length && {...mFilterData}) },
      }),
    });
    res.then((e) => e.json())
    .then((e)=> {
      if(e.success){
        const response = e?.response || {};
        if(response?.Status === "Success"){
          moveToNextStep()
          leadErrorHandler?.checkLeadFail && leadErrorHandler.checkLeadFail(false)
        }else if(response?.Status==="Error"){
          setMessage("We are encountering the server issue please try again.");
          setLeadError(true)
          if(leadErrorHandler?.checkLeadFail && !pathname.includes('/lp-nw')){
            leadErrorHandler.checkLeadFail(true)
          } else {
            setLeadFailedComp(false)
            moveToNextStep()
          }
          console.error("Error to update lead");
        }
      }
    }).catch((err)=>{
      console.error("error", err)
      setLeadError(true)
    })
 }

/**
 * This API is just for landing page(lp) right now.
 */ 
 const leadUpdateApiCallLandingPages = async (mFilterData = {}) => {

   const res = fetch("/api/leadsquared.com/lead-update-lp", {
     method: "POST",
     headers: {
      'Content-Type': 'application/json',
      },
     body: JSON.stringify({
       form: {
         leadId: leadResponse?.ProspectId || "",
         Custom5: "Verified",
         ...(Object.keys(mFilterData).length && { ...mFilterData }),
       },
     }),
   });

   res
     .then((e) => e.json())
     .then((e) => {
       if (e.success) {
         const response = e?.response || {};
         if (response?.Status === "Success") {
           setOTPSent(false);
           setIsOtpVerified(true);
           setMessage(null);
           setShowLoader(true);
           otpGaCb?.verifyOtpGaCb && otpGaCb.verifyOtpGaCb(leadResponse?.ProspectId);
         } else if (response?.Status === "Error") {
           setMessage("We are encountering the server issue please try again.");
           console.error("Error to update lead");
         }
       }
     })
 }; 

  const handleVerifyOTP = useCallback(
    (body: { otp: string } ) => {

      const payload = {
        hash: otpResponse.hash,
        mobile: otpResponse.mobile,
        otp: body.otp,
      };
      
      loading(true);

      fetch("/api/otp/verify", {
        method: "post",
        body: JSON.stringify(payload),
      })
      .then((d) => d.json())
      .then((d) => {
        // by pass otp verification if mobile number starts with 23
        if(otpResponse.mobile && (otpResponse.mobile.startsWith("23") || otpResponse.mobile.startsWith("123")) && !(pathname.includes('/corporates') || pathname.includes('/ip-listing') || pathname.includes('/contact-us'))){
          if(isLambdaAPI){
            leadUpdateApiCallLandingPages();
          }else{
            leadUpdateApiCall();
          }
          
          return;
        }
  
        if((pathname.includes('/corporates') || pathname.includes('/ip-listing') || pathname.includes('/contact-us')) && d.success){
          setOTPSent(false);
          setIsOtpVerified(true);
          setMessage(null);
          setShowLoader(true);

          otpGaCb?.verifyOtpGaCb && otpGaCb.verifyOtpGaCb();
        } 
        if(pathname.includes('/lp-nw') && d.success){
          pushToDataLayer({
            event: "form_otp_verify",
            cta_text : "Verify OTP",
            lead_starting_cta : "NA",
            lead_starting_url : window.location.href || "NA",
            lead_id : leadResponse?.ProspectId || "NA",
            section_name :  getGlobalStateValue('formSectionName') || "Connect With Our Counsellor",
            status : "success",
            error_message : "NA",
            page_type : getGlobalStateValue("pageType") || "Home Page"
          })
          const data = StorageService.get('mFitlerKeyDetails') || {};
        
          const { mf_call_recommendation = '' , mf_media_risk_level = '' , mf_token:Mf_token = '' , mf_trans_id= '' }  = data.botData || {}

          if(isLambdaAPI){
            leadUpdateApiCallLandingPages({ mf_call_recommendation, mf_media_risk_level, Mf_token, mf_trans_id });
          } else {
            leadUpdateApiCall({ mf_call_recommendation, mf_media_risk_level, Mf_token, mf_trans_id });
          }
        }
        else if (d.success) {
          pushToDataLayer({
            event: "form_otp_verify",
            cta_text : "Verify OTP",
            lead_starting_cta : getGlobalStateValue("pageType") === "Campaign Page" ? "NA" : ( getGlobalStateValue("leadStartingCta") || "NA"),
            lead_starting_url : window.location.href || "NA",
            lead_id : leadResponse?.ProspectId || "NA",
            section_name : getGlobalStateValue('formSectionName') || "Connect With Our Counsellor",
            status : "success",
            error_message : "NA",
            page_type : getGlobalStateValue("pageType") || "Home Page"
          })
          if(pathname.includes('ip-listing')){
            setOTPSent(false);
            setIsOtpVerified(true);
            setMessage(null);
            otpGaCb?.verifyOtpGaCb && otpGaCb.verifyOtpGaCb();
            setShowLoader(false);
            setTimeout(() => {
              setIsOtpVerified(false);  
            }, 100);
          }
          else{
            leadUpdateApiCall();
          }
          return 
        } else {
          pushToDataLayer({
            event: "form_otp_verify",
            cta_text : "Verify OTP",
            lead_starting_cta : getGlobalStateValue("pageType") === "Campaign Page" ? "NA" : (getGlobalStateValue("leadStartingCta") || "NA"),
            lead_starting_url : window.location.href || "NA",
            lead_id : leadResponse?.ProspectId || "NA",
            section_name : getGlobalStateValue('formSectionName') || "Connect With Our Counsellor",
            status : "fail",
            error_message : d.message || "NA",
            page_type : getGlobalStateValue("pageType") || "Home Page"
          })
          setMessage(d.message);
        }
      })
      .catch((e) => {
        console.error("error", e)
      })
      .finally(() => {
        loading(false);
      });
    },
    [formCallback, otpResponse, otpResponse?.hash]
  );

  const value = useMemo(() => {
    return {
      isOTPLoading: isLoading,
      isOTPSent,
      showLoader,
      message: otpMessage,
      formCallback,
      otpGaCb,
      otpResponse,
      setOTPSent,
      setShowLoader,
      setMessage,
      handleSendOTP,
      handleVerifyOTP,
      setLeadResponse,
      otpVerified,
      isInternationalNumber,
      leadResponse,
      isHeaderHidden,
      setIsHeaderHidden,
      isNewSignInFlow,
      setIsNewSignInFlow,
      leadError,
      leadFailedComp,
      setLeadFailedComp,
      handleResendOTP
    };
  }, [
    isLoading,
    isOTPSent,
    showLoader,
    otpMessage,
    formCallback,
    otpGaCb,
    otpResponse,
    setOTPSent,
    setShowLoader,
    setMessage,
    handleSendOTP,
    handleVerifyOTP,
    setLeadResponse,
    otpVerified,
    isInternationalNumber,
    leadResponse,
    isHeaderHidden,
    setIsHeaderHidden,
    leadError,
    leadFailedComp,
    handleResendOTP
  ]);

  return <OTPContext.Provider value={value}>{children}</OTPContext.Provider>;
}

export const useOTPContext = () => useContext(OTPContext);

export const OTPContextWrapper = ({ children, isLambdaAPI= false }) => {
  const { isOTPSent, setOTPSent, setMessage, showLoader, setShowLoader, leadError, leadFailedComp} =
    useOTPContext();

  const pathname = usePathname();

  useEffect(() => {
    return () => {
      setOTPSent(false);
      setShowLoader(false);
      setMessage(null);
    };
  }, []);

  return (
    <>
      <div className={`${(isOTPSent || (showLoader && !isLambdaAPI)) || leadError ? "hidden" : ""}`}>
        {children}
      </div>
      {isOTPSent && !leadError && <VerifyOTPForm />}
      {leadError && leadFailedComp && !pathname.includes('/lp-nw') && <LeadAPIFailedErrorMsg />}
      {showLoader && !isLambdaAPI && <Loader />}
    </>
  );
};
function err(reason: any): PromiseLike<never> {
  throw new Error("Function not implemented.");
}

